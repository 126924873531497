<template>
    <div class="basic_data_edit">
        <div class="data_content">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">民居建设监测</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <div class="title-part">监测数据</div>
            <div class="flex flex-wrap">
                <el-form
                    ref="formdata"
                    :inline="true"
                    :model="formdata.dataDic"
                    :rules="rules"
                    class="form_row"
                    size="small"
                    label-width="200px"
                >
                    <el-form-item
                        v-for="(item, i) in monitorList"
                        :key="i"
                        style="width: 50%"
                        :label="item.label"
                        :prop="item.value"
                    >
                        <el-input
                            v-model="formdata.dataDic[item.value]"
                            :disabled="!isEdit"
                            :maxlength="50"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="title-part">基础数据</div>
            <div class="map">
                <MapDraw
                    :basicMap="taskMap"
                    :drawConf="drawConf"
                    :jsonStr="jsonStr"
                    @update:jsonStr="getJsonStr"
                >
                </MapDraw>
                <Mymap ref="myMap" :mapData="mapData" :myMap.sync="taskMap">
                </Mymap>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="form_row"
                size="small"
                label-width="200px"
            >
                <div class="flex-between">
                    <div style="width: 50%">
                        <el-form-item
                            prop=""
                            label="民居建设编号"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.XJXMBH"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="村"
                            class="from-item-block"
                        >
                            <el-select
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.YCCZ"
                                filterable
                                clearable
                                @change="getGroupList()"
                            >
                                <el-option
                                    v-for="(item, index) in countryList"
                                    :key="index"
                                    :label="item.MC"
                                    :value="item.MC"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="" label="组" class="from-item-block"
                            ><el-select
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.YCYS"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(item, index) in countryGroupList"
                                    :key="index"
                                    :label="item.MC"
                                    :value="item.MC"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="监测日期" style="width: 100%">
                            <el-date-picker
                                v-model="formdata.dataDic.CJSJ"
                                placeholder="选择日期"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :disabled="!isEdit"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="宅基地情况"
                            style="width: 100%"
                        >
                            <el-radio-group
                                v-model="formdata.dataDic.ZJDQK"
                                :disabled="!isEdit"
                            >
                                <el-radio
                                    style="line-height: 30px"
                                    v-for="v in homestead"
                                    :key="v.LABEL"
                                    :label="v.CODE"
                                    >{{ v.LABEL }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="宅基地面积（m²）"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.ZJDMJ"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>

                        <el-form-item
                            prop=""
                            label="申请占地面积（m²）"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SQZDMJ"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="实际占地面积（m²）"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SJZDMJ"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="申请建筑面积（m²）"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SQJZMJ"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="实际建筑面积（m²）"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SJJZMJ"
                                :disabled="!isEdit"
                                :maxlength="50"
                                class="cell"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="施工单位"
                            style="width: 100%"
                            ><el-input
                                v-model="formdata.dataDic.SGDW"
                                :disabled="!isEdit"
                                :maxlength="50"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="施工负责人"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SGFZR"
                                :disabled="!isEdit"
                                :maxlength="50"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="施工联系电话"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SGDWDH"
                                :disabled="!isEdit"
                                :maxlength="50"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设地点"
                            prop=""
                            style="width: 100%"
                        >
                            <el-select
                                v-model="formdata.dataDic.JSDD"
                                :disabled="!isEdit"
                                :clearable="true"
                            >
                                <el-option
                                    v-for="(item, index) in JSDDList"
                                    :key="`${index}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="项目名称"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.XMMC"
                                :disabled="!isEdit"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%">
                        <el-form-item
                            prop=""
                            label="户主姓名"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.HZXM"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input
                        ></el-form-item>
                        <el-form-item
                            prop=""
                            label="家庭人口"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.JTRK"
                                :disabled="!isEdit"
                                :maxlength="50"
                                class="cell"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="身份证号码"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.SFZHM"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="联系电话"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.MOBILE"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input
                        ></el-form-item>
                        <el-form-item
                            prop=""
                            label="建设方案是否通过审批"
                            style="width: 100%"
                            ><el-radio-group
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.SFSPTG"
                            >
                                <el-radio
                                    style="line-height: 30px"
                                    v-for="v in approvalList"
                                    :key="v.CODE"
                                    :label="v.CODE"
                                    >{{ v.LABEL }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="房屋结构"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.FWJG"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="房屋用途"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.FWYT"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>

                        <el-form-item
                            prop=""
                            label="房屋层数"
                            style="width: 100%"
                            ><el-input
                                v-model="formdata.dataDic.FWCS"
                                :disabled="!isEdit"
                                :maxlength="50"
                                size="mini"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="房屋高度"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.FWGD"
                                :disabled="!isEdit"
                                :maxlength="50"
                                size="mini"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="外墙颜色"
                            style="width: 100%; height: 30px"
                        >
                            <div
                                style="
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 4px;
                                "
                                :style="{ background: formdata.dataDic.WQYS }"
                            ></div>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="屋顶形式"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="formdata.dataDic.WDXS"
                                :disabled="!isEdit"
                                :maxlength="50"
                                size="mini"
                            ></el-input>
                        </el-form-item>

                        <el-form-item
                            prop=""
                            label="建房时间"
                            style="width: 100%"
                            ><el-date-picker
                                v-model="formdata.dataDic.JFRQ"
                                placeholder="选择日期"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :disabled="!isEdit"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item
                            prop=""
                            label="计划完成时间"
                            style="width: 100%"
                        >
                            <el-date-picker
                                v-model="formdata.dataDic.JHJGRQ"
                                placeholder="选择日期"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :disabled="!isEdit"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label=" " prop="" style="width: 100%">
                            <el-select
                                v-model="formdata.dataDic.QTJSDD"
                                :disabled="!isEdit"
                                :clearable="true"
                            >
                                <el-option
                                    v-for="(item, index) in QTJSDD"
                                    :key="`${index + 99}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="" label="备注" style="width: 100%">
                            <el-input
                                v-model="formdata.dataDic.XMSM"
                                :disabled="!isEdit"
                                :maxlength="50"
                                clearable
                                type="textarea"
                                :row="3"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item prop="" label="项目调研" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.XMDYID"
                        :disabled="!isEdit"
                        clearable
                        @change="xmdyChange()"
                    >
                        <el-option
                            v-for="(item, index) in xmdyList"
                            :key="index"
                            :value="item.ID"
                            :label="item.XMMC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="调研附件" class="from-item-block" prop="">
                    <div class="imgPart scrollbar">
                        <SnFileDialog :fileData="fileData"> </SnFileDialog>
                    </div>
                </el-form-item>

                <el-form-item
                    prop=""
                    label="新建项目工程方案"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="projectList"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
                <el-form-item
                    prop=""
                    label="工程方案批复文件"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="gcfapfwjList"
                        basicType="documentWordPdf"
                        :limit="1"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
                <el-form-item
                    prop=""
                    label="工程方案变更"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="projectChangeList"
                        basicType="document"
                        :limit="1"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import MapDraw from "@comp/map/mapDraw/MapDraw";
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xjxm",
    components: {
        MapDraw,
    },
    mixins: [infoMixin],
    data() {
        return {
            mapData: {
                zoom: 12.6,
                baseMapNum: 1,
                mapTypeBtn: false,
                areaState: true,
                dxtState: true,
            },
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "90502",
                dataDic: {
                    glycbtid: this.heritageId,
                    XMWZSL: "", //json字符串

                    XJXMBH: "", //民居建设编号
                    YCCZ: "", //村
                    YCYS: "", //村民小组(组)
                    CJSJ: this.common.getTimeYmd(), //监测日期

                    HZXM: "", //户主姓名
                    JTRK: "", //家庭人口
                    SFZHM: "", //身份证号码
                    MOBILE: "", //联系电话

                    ZJDQK: "0", //宅基地情况
                    ZJDMJ: "", //宅基地面积

                    SFSPTG: "1", //建设方案是否审批通过
                    FWJG: "", //房屋结构
                    FWYT: "", //房屋用途

                    SQZDMJ: "", //申请占地面积
                    SJZDMJ: "", //实际占地面积
                    SQJZMJ: "", //申请建筑面积
                    SJJZMJ: "", //实际建筑面积

                    FWCS: "", //房屋层数
                    FWGD: "", //房屋高度
                    WQYS: "#409EFF", //外墙颜色
                    WDXS: "", //屋顶形式

                    JFRQ: null, //建房时间
                    JHJGRQ: null, //计划竣工时间
                    JGSJ: null, //竣工时间

                    SGDW: "", //施工单位
                    SGFZR: "", //施工负责人
                    SGDWDH: "", //施工单位联系电话

                    XMSM: "", //备注
                    JSDD: "", //建设地点
                    QTJSDD: "", //其他建设地点

                    XMMC: "", //项目名称

                    CJRID: localStorage.userId,
                    RWID: JSON.parse(this.$route.query.val).RWID,
                    XMDYID: "",
                },
            },
            rules: {
                // XMMC: [
                //     {
                //         required: true,
                //         message: "请输入项目名称",
                //         trigger: "blur",
                //     },
                // ],
            },
            projectList: [], //新建项目工程方案
            gcfapfwjList: [], //工程方案批复文件
            projectChangeList: [], //工程方案变更
            drawConf: {
                position: "topleft",
                show: true,
                draw: {
                    polygon: true, //多边形
                    polyline: false,
                    rectangle: true, //矩形
                },
            },
            jsonStr: null,
            taskMap: null,
            countryList: [], // 行政村
            countryGroupList: [], // 村民小组
            homestead: [], // 宅基地情况
            approvalList: [], // 审批
            JSDDList: [], //建设地点
            QTJSDD: [], //其他建设地点
            xmdyList: [], //项目调研列表
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
            // 监测数据列表
            monitorList: [
                {
                    label: "框架：",
                    value: "KJ_DESC",
                },
                {
                    label: "封顶：",
                    value: "FD_DESC",
                },
                {
                    label: "砌墙：",
                    value: "QQ_DESC",
                },

                {
                    label: "室内装修：",
                    value: "SNZX_DESC",
                },
                {
                    label: "室外装修：",
                    value: "SWZX_DESC",
                },
                {
                    label: "竣工：",
                    value: "JG_DESC",
                },

                {
                    label: "现状描述：",
                    value: "XZMS",
                },
                {
                    label: "存在问题：",
                    value: "CZWT",
                },
                {
                    label: "是否按已审批方案实施：",
                    value: "SFAYSPFASS_DESC",
                },
            ],
        };
    },
    watch: {
        projectList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        gcfapfwjList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        projectChangeList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        formdata: {
            deep: true,
            handler: function () {
                if (this.formdata.dataDic.XMWZSL) {
                    this.jsonStr = this.formdata.dataDic.XMWZSL;
                }
            },
        },
    },
    created() {},
    mounted() {
        this.getXmdyList();
        this.getCountryList();
        this.getGroupList();
        this.GetEnum();
    },
    methods: {
        ...mapActions([
            "GetXZQHByCode",
            "getAllEnumInfobytableID",
            "XjxmGetXmdyList",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 获取 村 列表
        async getCountryList() {
            let result = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: "",
            });
            this.countryList = result.RESULTVALUE;
        },
        // 获取 小组 列表
        async getGroupList() {
            this.formdata.dataDic.YCYS = "";
            let result = await this.GetXZQHByCode({
                code: this.formdata.dataDic.YCYS
                    ? this.formdata.dataDic.YCYS
                    : "",
                userid: "",
                lx: 1, //0 村 1组
            });
            this.countryGroupList = result.RESULTVALUE;
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "90501",
            });
            let enumList = res || [];
            enumList.forEach((item) => {
                switch (item.COLUMNID) {
                    case "SFSPTG":
                        this.approvalList = item.dataItems;
                        break;
                    case "ZJDQK":
                        this.homestead = item.dataItems;
                        break;
                    case "JSDD":
                        this.JSDDList = item.dataItems;
                        break;
                    case "QTJSDD":
                        this.QTJSDD = item.dataItems;
                        break;
                }
            });
        },
        getFile() {
            let listid = [];
            let list = [];
            this.projectList.forEach((v) => {
                listid.push(`9020101_${v.jcsjid}`);
                list.push(v);
            });
            this.gcfapfwjList.forEach((v) => {
                listid.push(`9020102_${v.jcsjid}`);
                list.push(v);
            });
            this.projectChangeList.forEach((v) => {
                listid.push(`9020103_${v.jcsjid}`);
                list.push(v);
            });
            this.formdata.fileids = listid.join(",");
            this.formdata.metaJson = list;
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            let typeList = ["draw:created", "draw:editstop", "draw:deleted"]; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.dataDic.XMWZSL = JsonStr;
            }
        },
        GetDataNext() {
            if (!this.formdata.dataDic.JGSJ) {
                this.formdata.dataDic.JGSJ = null;
            }

            if (!this.formdata.dataDic.JHJGRQ) {
                this.formdata.dataDic.JHJGRQ = null;
            }

            if (!this.formdata.dataDic.JFRQ) {
                this.formdata.dataDic.JFRQ = null;
            }

            if (this.formdata.metaJson && this.formdata.metaJson[0]) {
                this.projectList = this.formdata.metaJson[0];
            }

            if (this.formdata.metaJson && this.formdata.metaJson[1]) {
                this.gcfapfwjList = this.formdata.metaJson[1];
            }

            if (this.formdata.metaJson && this.formdata.metaJson[2]) {
                this.projectChangeList = this.formdata.metaJson[2];
            }

            if (this.formdata.dataDic.XMDYID) {
                this.xmdyChange();
            }
        },
        // 获取项目调研列表
        async getXmdyList() {
            let result = await this.XjxmGetXmdyList({});
            this.xmdyList = result.RESULTVALUE;
        },
        xmdyChange() {
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            let list = [];
            if (this.formdata.dataDic.XMWZSL) {
                JSON.parse(this.formdata.dataDic.XMWZSL).map((item) => {
                    if (!item.properties.from) {
                        list.push(item);
                    }
                });
            }

            if (this.formdata.dataDic.XMDYID) {
                let obj = this.common.aryListFindOne(
                    this.xmdyList,
                    "ID",
                    this.formdata.dataDic.XMDYID
                );
                if (!this.formdata.dataDic.XMMC) {
                    this.formdata.dataDic.XMMC = obj.XMMC;
                }
                let markerList = [
                    {
                        type: "Feature",
                        properties: {
                            from: "项目调研",
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [obj.JD, obj.WD],
                        },
                    },
                ];
                this.formdata.dataDic.XMWZSL = JSON.stringify([
                    ...list,
                    ...markerList,
                ]);
            } else {
                this.formdata.dataDic.XMWZSL = JSON.stringify(list);
            }
            this.xmdyList.map((item) => {
                if (this.formdata.dataDic.XMDYID == item.ID) {
                    item.CHILD.map((itemInner) => {
                        let dw = itemInner.FJLJ.substring(
                            itemInner.FJLJ.lastIndexOf(".") + 1
                        );
                        switch (dw) {
                            case "mp3":
                                this.fileData.mp3List.push(itemInner.FJLJ);
                                break;
                            case "mp4":
                                this.fileData.mp4List.push(itemInner.FJLJ);
                                break;
                            case "jpg":
                            case "png":
                            case "jpeg":
                            case "gif":
                            case "tiff":
                                this.fileData.imgList.push(itemInner.FJLJ);
                                break;
                            default:
                                this.fileData.othersPart.list.push(itemInner);
                                break;
                        }
                    });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.map {
    width: 100%;
    position: relative;
    height: 370px;
}

.title-part {
    font-size: 16px;
    color: #606266;
    font-weight: 700;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-top: 10px;
    &::before {
        content: "|";
        width: 1px;
        height: 10px;
        background: #336799;
        margin-right: 10px;
    }
}
</style>
